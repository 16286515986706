import cable from '../plugins/aсtionCable';
import { IAudiobookCommentSocket } from '../models/audiobook';

const ChannelAdminComments = cable.subscriptions.create(
  {
    channel: 'AdminCommentsChannel',
  },
  {
    connected() {
      console.log('ChannelAdminComments -> connected')
      this.perform('follow_admin_comments',{})
    },
    disconnected() {},
    received(data:IAudiobookCommentSocket) {},
  }
)
export default ChannelAdminComments
