import React from 'react';
import { IStatSocketSingle } from '../../models/stat';
import { IconButton, List, Menu } from '@material-ui/core';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ReportMessengerListItem from './ReportMessengerListItem';
import { Badge } from '@mui/material';
import ConnectionStatus from '../../actionCables/ConnectionStatus';
import useIsOpen from '../../utils/hooks/useIsOpen';

const ReportMessengerList:React.FC<{newReports:IStatSocketSingle[], isConnected: boolean}>
  = ({isConnected, newReports}) => {
  const {isOpen, toggleOpen} = useIsOpen(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleCommentList = (event: React.MouseEvent<HTMLElement>) => {
    toggleOpen()
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    toggleOpen()
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="new-reports"
        disabled={newReports.length === 0}
        onClick={handleCommentList}>
        <ConnectionStatus isConnected={isConnected}>
        <Badge color="warning" badgeContent={newReports.length}>
          <QueryStatsIcon />
        </Badge>
        </ConnectionStatus>
      </IconButton>
      <Menu
        id="reports"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        <List style={{maxHeight: newReports.length === 0 ? '0' : '300px'}}>
          {
            newReports.map((comment, index) => (
              <ReportMessengerListItem comment={comment} key={index}/>
            ))
          }
        </List>
      </Menu>
    </>
  )
}
export default ReportMessengerList
