import React from 'react';
import { List } from '@material-ui/core';
import { IAudiobookComment } from '../../models/audiobook';
import NewComment from './NewComment';

const NewCommentsList:React.FC<{newComments:IAudiobookComment[], onMarkAsRead(comment:IAudiobookComment):void}>
  = ({newComments, onMarkAsRead}) => {
  return (
    <List style={{maxHeight: newComments.length === 0 ? '0' : '300px'}}>
      {
        newComments.map((comment, index)=>(
          <NewComment
            markAsRead={onMarkAsRead}
            comment={comment}
            key={index}
          />
        ))
      }
    </List>
  )
}
export default NewCommentsList
