import React from "react"
import { Badge } from '@mui/material';

const ConnectionStatus:React.FC<{isConnected:boolean}>
  = ({isConnected,children}) => {
  return (
    <Badge
      color={isConnected ? 'success' : 'error'}
      badgeContent={isConnected ? 'connected' : 'disconnected'}
      variant="dot"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </Badge>
  )
}
export default ConnectionStatus
