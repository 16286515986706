import {createContext} from "react";
import {ILoginState} from "./ILoginState";
import {getAuthData} from "../../utils/auth";
export const initState: ILoginState = {
  isAuth: getAuthData() !== null,
  title: '',
  profile: null,
  authData: getAuthData(),
  setAuthDataState: () => {},
  setIsAuth: () => {},
  setTitle: () => {},
  setProfile: () => {}
}
// interface ILoginProps {
//   state: ILoginState;
//   dispatch: ({type}:{type:string}, payload: any) => void;
// }

export const LoginContext = createContext(initState)
  // state: typeof initState;
  // dispatch?: (action: any) => void;

