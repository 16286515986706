import {
  IAudiobook,
  IAudiobookCachedGet,
  IAudiobookComment,
  IAudiobookCommentGet,
  IAudiobookCommentPost, IAudiobookEdition, IAudiobookEditionsGet
} from '../models/audiobook';
import axios from "axios";
import {IStatLicenses} from "../models/statLicenses";
import pagination from './utils/pagination';

class AudiobookService {
  public static async getAudiobook(audiobookId: string):Promise<IAudiobook> {
    const audiobookData = await axios  // ${process.env.REACT_APP_API_STG}
      .get(`audiobooks/${audiobookId}`)
    return audiobookData.data.data
  }
  public static async getAudiobookPlaylists(audiobookId: string) {
    const audiobookData = await axios  // ${process.env.REACT_APP_API_STG}
      .get(`audiobooks/${audiobookId}/associated_playlists`)
    return audiobookData.data.data
  }
  public static async postAudiobook(data: IAudiobook) {
    data.id = Date.now()
    // data.author ="Some author"
    // data.findawayId = 999999
    // data.model = "Some model"
    // data.playtime= '09:09:09'
    // data.publisher= "some publisher"
    return data
  }

  public static async updateAudiobook(data: IAudiobook) {
    return data
  }


  public static async getLicense(audiobookId: string): Promise<IStatLicenses | null> {
    const audiobookData = await axios.get(`audiobooks/${audiobookId}/licenses`)
    return audiobookData.data.data
  }

  public static async postLicenses(findawayId: number): Promise<IStatLicenses> {
    const audiobookData = await axios.post(`audiobooks/create_license`, {
      "licenses": {
        "book_id": findawayId
      }
    })
    return audiobookData.data.data
  }

  public static async cacheAudiobook(data: {audiobook_id: number }): Promise<'success'> {
    const cacheData = await axios.patch(`audiobooks/${data.audiobook_id}/cache`)
    return cacheData.data
  }
  public static async forceUpdate(data: {audiobook_id: number}): Promise<'success'> {
    const cacheData = await axios.patch(`audiobooks/${data.audiobook_id}/force_update`)
    return cacheData.data
  }
  public static async getCacheAudiobook(audiobookId:string):Promise<IAudiobookCachedGet> {
    const cacheData = await axios.get(`audiobooks/${audiobookId}/audiobook_chapters`)
    return pagination(cacheData)
  }
  public static async getCommentsAudiobook(audiobookId:string):Promise<IAudiobookCommentGet> {
    const commentsData = await axios.get(`audiobooks/${audiobookId}/comments`)
    return pagination(commentsData)
  }
  public static async getAudiobookComment(data:{audiobookId:string, commentId: string}):Promise<IAudiobookComment> {
    const { audiobookId, commentId } = data
    const commentsData = await axios.get(`audiobooks/${audiobookId}/comments/${commentId}`)
    return commentsData.data.data
  }
  public static async getAudiobookEditions(data:any):Promise<IAudiobookEditionsGet> {
    const { audiobookId, page, per_page } = data
    const editionsData = await axios.get(`audiobooks/${audiobookId}/editions`,{
      params: {
        page,
        per_page
      }
    })
    return pagination(editionsData)
  }
  public static async getAudiobookEdition(data:{audiobookId:string, editionId: string}):Promise<IAudiobookEdition> {
    const { audiobookId, editionId } = data
    const editionsData = await axios.get(`audiobooks/${audiobookId}/editions/${editionId}`)
    return editionsData.data.data
  }

  public static async postCommentAudiobook(data:{ audiobookId: string, commentData: IAudiobookCommentPost }):Promise<IAudiobookComment> {
    const { audiobookId, commentData } = data
    const commentsData = await axios.post(`audiobooks/${audiobookId}/comments`, {
      comment: {
        ...commentData
      }
    })
    return commentsData.data.data
  }
  public static async patchCommentAudiobook(data:{ audiobookId: string, commentData: IAudiobookCommentPost }):Promise<IAudiobookComment> {
    const { audiobookId, commentData } = data
    const commentsData = await axios.patch(`audiobooks/${audiobookId}/comments/${commentData.id}`, {
      comment: {
        ...commentData
      }
    })
    return commentsData.data.data
  }
  public static async deleteCommentAudiobook(data:{ audiobookId: string, commentId: string }) {
    const { audiobookId, commentId } = data
    const commentsData = await axios.delete(`audiobooks/${audiobookId}/comments/${commentId}`)
    return pagination(commentsData)
  }

  public static async getCachedEditions(data:any):Promise<IAudiobookEditionsGet> {
    const { page, per_page } = data
    const editionsData = await axios.get(`editions`,{
      params: {
        page,
        per_page,
        order: "updated_at",
        direction: "desc",
        cache_status: "cached",
        extend_book: "true"
      }
    })
    return pagination(editionsData)
  }
}

export default AudiobookService
