import React, { useContext, useEffect } from 'react';
import { LoginContext } from '../../context/login/loginContext';
import { IStatSocket, IStatSocketSingle } from '../../models/stat';
import ReportMessengerSingle from './ReportMessengeSingle';
import ReportMessengerList from './ReportMessengerList';
import ChannelExport from '../../actionCables/ChannelExport';

const ReportMessenger:React.FC = () => {
  const {profile} = useContext(LoginContext)
  const [newReports, setNewReports] = React.useState<IStatSocketSingle[]>([])
  const [isConnected, setIsConnected] = React.useState<boolean>(false)

  useEffect(() => {
    if(profile) {
      ChannelExport.connected()
      ChannelExport.postConnect(profile.id)
      ChannelExport.received = (data: { data: IStatSocket[] | IStatSocketSingle }) => {
        console.log('ChannelExport received -> data', data)
        if (Array.isArray(data.data)) {
          console.log('ChannelExport received -> arr data', data)
          const transformData = data.data.map(report => {
            return {
              export_history_url: null,
              percent: 0,
              ...report.attributes
            }
          })
          setNewReports(transformData)
        } else {
          // @ts-ignore
          setNewReports(prevState => [...prevState.map(item=>item.id === data.data.id as number
            // @ts-ignore
            ? {...item, percent: data.data.percent, export_history_url:data.data.export_history_url } : item)])

        }
      }
      //ChannelExport.perform('receive',{id:profile.id})
      setIsConnected(true)
    }
    // eslint-disable-next-line
  }, [profile]);

  return (
    <>
      {
      newReports.length === 1 ?
        <ReportMessengerSingle
          percent={newReports[0].percent}
          link={newReports[0].export_history_url}
          isConnected={isConnected}
        />
        :
        <ReportMessengerList newReports={newReports} isConnected={isConnected}/>
    }
    </>
  )
}
export default ReportMessenger
