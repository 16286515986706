import { ITableState } from './ITableState';
import { SET_SORT_DATA } from '../types';
import { Direction } from '../../models/misc';

const handlers = {
  [SET_SORT_DATA]: (state:ITableState, payload:{payload: { order: string, direction: Direction }, type: 'SET_SORT_DATA'}) => ({...state, sortData: payload.payload}),
  DEFAULT: (state:any) => state
}

export const tableReducer = (state: ITableState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
