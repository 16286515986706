import cable from '../plugins/aсtionCable';

const ChannelExport = cable.subscriptions.create(
  {
    channel: 'ExportChannel',
  },
  {
    connected() {
      // console.log('ChannelExport -> connected')
      // this.perform('follow_admin_export', {id:70})
      // this.perform('receive',{id:70})
    },
    postConnect(id:string | undefined) {
      if(typeof id !== 'undefined') {
        this.perform('follow_admin_export', {id})
        this.perform('receive',{id})
      }
    },
    disconnected() {

    },
    received(data:any) {

    },
  }
)
export default ChannelExport
