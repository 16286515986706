import {createContext} from 'react'

export const initAlertState: any = {
  visible:false,
  alert: {text:''},
  hide: () => {},
  show: (message: string, variant: 'danger' | 'success') => {}
}

export const AlertContext = createContext(initAlertState)
