import axios from 'axios';
import { IAudiobookCommentGet } from '../models/audiobook';
import pagination from './utils/pagination';

class CommentsService {
  public static async getComments(data:any):Promise<IAudiobookCommentGet> {
    const { page, per_page, new_comment} = data
    const categoryData = await axios
      .get(`comments`,
        {
          params: {
            page,
            per_page,
            new_comment
          }
        }
      )
    return pagination(categoryData)
  }
}
export default CommentsService
