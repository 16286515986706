import React from 'react';
import { IStatSocketSingle } from '../../models/stat';
import { makeStyles } from '@material-ui/core/styles';
import { Link, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { formatAsDateTimeWithSeconds } from '../../utils/luxonFormatter';

const useStyles = makeStyles({
  listContainer: {
    maxWidth:'520px',
    minWidth:'520px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  textPreview: {
    opacity: '0.7',
    maxWidth:'500px',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});
const ReportMessengerListItem:React.FC<{comment:IStatSocketSingle}> = ({ comment }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listContainer}
    >
      <ListItemAvatar>
        <>
          {
            comment.export_history_url === null ?
              `${comment.percent}%`
            : <Link href={comment.export_history_url}>
                link
              </Link>
          }
        </>
      </ListItemAvatar>
      <ListItemText
        primary={comment.template}
        secondary={formatAsDateTimeWithSeconds(comment.created_at)} />
    </ListItem>
  )
}
export default ReportMessengerListItem
