import { DateTime } from 'luxon';
/**
 * formatAsDateShort return 'yyyy-mm-dd' based on local format
 * @param {string} isoDate - date as string
 */
export const formatAsDateShort = (isoDate: string):string => {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_SHORT)
}
/**
 * formatAsDateTime return 'yyyy-MM-dd hh:mm' based on local format
 * @param {string} isoDate - date as string
 */
export const formatAsDateTime = (isoDate: string):string => {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_SHORT)
}
/**
 * formatMsAsDateTime return 'yyyy-MM-dd hh:mm' based on local format
 * @param {number} msTimestamp - date as number in ms
 */
export const formatMsAsDateTime = (msTimestamp: number):string => {
  return DateTime.fromMillis(msTimestamp).toLocaleString(DateTime.DATETIME_SHORT)
}
/**
 * formatAsDateTimeWithSeconds return 'yyyy-MM-dd hh:mm:ss' based on local format
 * @param {string} isoDate - date as string
 */
export const formatAsDateTimeWithSeconds = (isoDate: string):string => {
  return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}
/**
 * formatMsAsDateTimeWithSeconds return 'yyyy-MM-dd hh:mm:ss' based on local format
 * @param {number} msTimestamp - date as number in ms
 */
export const formatMsAsDateTimeWithSeconds = (msTimestamp: number):string => {
  return DateTime.fromMillis(msTimestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
}

