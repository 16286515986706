import {AuthData, AuthHeaders, AuthInfo, SignIn} from "../models/auth";
import {authService, config} from "../plugins/axios";

class AuthService {
  public static async auth(credentials: SignIn): Promise<AuthData> {
    const authData = await authService
      .post(`auth/sign_in`, {
        user: {
          email: credentials.email,
          password: credentials.password
        }
      })
    let headers = AuthService.getHeaders(authData.headers);
    let data: AuthInfo = authData.data.data;
    let resp: AuthData = { headers, data };
    return resp;
  }
  public static getHeaders(headers: any): AuthHeaders {
    return {
      'access-token': headers['access-token'],
      'token-type': headers['token-type'],
      client: headers.client,
      expiry: headers.expiry,
      uid: headers.uid
    };
  }
  public static async getProfile() {
    if(typeof config.headers !== 'undefined') {
      const profile = await authService
        .get(`profiles/me`, {
          headers: config,
          params: {
            "page": "1",
            "per_page": "10",
            "limited": "true"
          }
        })
      return profile.data.data
    }
  }
}

export default AuthService
