import React, {useReducer} from "react";
import {initState, LoginContext} from "./loginContext";
import {loginReducer} from "./loginReducer";
import {SET_AUTH_DATA, SET_IS_AUTH, SET_TITLE,SET_PROFILE} from "../types";
import {removeAuthData, setAuthData} from "../../utils/auth";
import {AuthHeaders} from "../../models/auth";

export const LoginState:React.FC = ({children}) => {
  const [state,dispatch]= useReducer(loginReducer, initState);
  const setIsAuth = (payload:boolean) => {
    dispatch({type:SET_IS_AUTH, payload})
  }
  const setTitle = (payload:string) => {
    dispatch({type:SET_TITLE, payload})
  }
  const setProfile = (payload:string) => {
    dispatch({type:SET_PROFILE, payload})
  }
  const setAuthDataState = (payload: AuthHeaders|null) => {
    if(payload) {
      setAuthData(payload)
    } else {
      removeAuthData()
    }
    dispatch({type:SET_AUTH_DATA, payload})
  }
  return (
    <LoginContext.Provider value={
      {
        authData: state.authData,
        isAuth: state.isAuth,
        setIsAuth,
        setAuthDataState,
        title: state.title,
        setTitle,
        profile: state.profile,
        setProfile
      }
    }>
      {children}
    </LoginContext.Provider>
  )
}
