// @ts-nocheck

import React, { useContext, useEffect } from 'react';
import { Badge, CircularProgress, IconButton } from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
import { LoginContext } from '../../context/login/loginContext';
import { IAudiobookComment, IAudiobookCommentSocket } from '../../models/audiobook';
import NewCommentsList from './NewCommentsList';
import CommentsService from '../../service/comments';
import { useIsLoading } from '../../utils/hooks/useIsLoading';
import { AlertContext } from '../../context/alert/alertContext';
import AudiobookService from '../../service/audiobook';
import { Menu } from '@mui/material';
import ChannelAdminComments from '../../actionCables/ChannelAdminComments';
import useIsOpen from '../../utils/hooks/useIsOpen';

const CommentMessenger = () => {
  const {profile} = useContext(LoginContext)
  const alert = useContext(AlertContext)

  const {isOpen, toggleOpen} = useIsOpen(false)

  const [newComments, setNewComments] = React.useState<IAudiobookComment[]>([])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { isLoading, loadingHandler } = useIsLoading()

  const handleCommentList = (event: React.MouseEvent<HTMLElement>) => {
    toggleOpen()
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    toggleOpen()
    setAnchorEl(null);
  };
  const getComments = async () => {
    loadingHandler(true)
    try {
      const data = await CommentsService.getComments({ page: 1, per_page: 30,new_comment:true })
      setNewComments(data.items)
      loadingHandler(false)
    } catch (e) {
      loadingHandler(false)
      alert.show("Something went wrong", 'danger')
    }
  }
  const onMarkAsRead = async (comment:IAudiobookComment)=> {
    try {
      const data = await AudiobookService.patchCommentAudiobook({
        audiobookId: comment.attributes.audiobook_id.toString(),
        commentData: {
          id: comment.id,
          title: comment.attributes.title,
          rating: comment.attributes.rating,
          new_comment: false,
          body: comment.attributes.body
        }
      })
      setNewComments(newComments=>newComments.filter(com=>com.id !== comment.id))
      console.log('onMarkAsRead -> data', data)
    } catch (e) {
      alert.show("Something went wrong", 'danger')
    }
  }

  useEffect(() => {
    if(profile){
      ChannelAdminComments.connected()
      getComments()
    }
    // eslint-disable-next-line
  }, [profile]);
  useEffect(() => {
    ChannelAdminComments.received = (resp:IAudiobookCommentSocket) => {
      setNewComments(newComments=>[...newComments, resp.comment.data])
    }
  },[])
  return (
    <>
      <IconButton
        aria-label="new-comments"
        disabled={newComments.length === 0}
        onClick={handleCommentList}>
        <Badge color="secondary" badgeContent={newComments.length}>
          <ChatIcon />
        </Badge>
      </IconButton>
      <Menu
        id="comments"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        {
          isLoading ?
            <div className='loader-container'><CircularProgress /></div>
            :
            <NewCommentsList
              onMarkAsRead={onMarkAsRead}
              newComments={newComments}/>
        }
      </Menu>
    </>
  )
}
export default CommentMessenger
