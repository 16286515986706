import React, {useReducer} from 'react'
import {AlertContext} from './alertContext'
import {alertReducer} from './alertReducer'
import {HIDE_ALERT, SHOW_ALERT} from '../types'
export type alertType = 'success' | 'info' | 'warning' | 'danger'

export const AlertState:React.FC = ({children}) => {
  const [state, dispatch] = useReducer(alertReducer, {visible: false})

  const show = (text: any, type:alertType = 'warning') => {

    dispatch({
      type: SHOW_ALERT,
      // @ts-ignore
      payload: {text, type}
    })
  }

  const hide = () => dispatch({type: HIDE_ALERT})

  return (
    <AlertContext.Provider value={{
      show, hide,
      alert: state
    }}>
      {children}
    </AlertContext.Provider>
  )
}
