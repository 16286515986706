// @ts-nocheck

import React, { useEffect, ComponentClass } from 'react';
import { DateTime } from 'luxon/src/luxon';
import packageJson from "../../../package.json";

const buildDateGreaterThan = (latestDate: number, currentDate: number) => {
  const momLatestDateTime = DateTime.fromMillis(latestDate);
  const momCurrentDateTime = DateTime.fromMillis(currentDate);

  return momLatestDateTime < momCurrentDateTime
};

function withClearCache(Component: ComponentClass) {
  function ClearCacheComponent(props: JSX.IntrinsicAttributes) {
    const [isLatestBuildDate, setIsLatestBuildDate] = React.useState(false);

    useEffect(() => {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
      // eslint-disable-next-line
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      hardReload()
    };
  const hardReload = () => {
    // const form = document.createElement('form');
    // form.method = "POST";
    // form.action = window.location.href;
    // document.body.appendChild(form);
    // form.submit();
    window.location.reload()
  }
    return (
      <>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
