import {AuthHeaders} from "../models/auth";

const getAuthData = (): AuthHeaders | null => {
  let authHeader: any
  // if (localStorage === undefined) {
  //   const tmp_storage:any = {};
  //   const p = '__unique__';  // Prefix all keys to avoid matching built-ins
  //   Storage.prototype.getItem = function(k:string){
  //     return tmp_storage[p + k] === undefined ? null : tmp_storage[p + k];
  //   };
  //   authHeader = Storage.prototype.getItem('authAnyplay')
  // } else {
     authHeader = window.localStorage.getItem('authAnyplay') // getCookieValue('authAnyplay');
  // }

  if (authHeader) {
    if (authHeader !== '') {
      return JSON.parse(authHeader) as AuthHeaders;
    }
    return null;
  }
  return null;
};
// function getCookieValue(a:string) {
//   const b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
//   return b ? b.pop() : '';
// }

const setAuthData = (authData: AuthHeaders): void => {
 // if (localStorage === undefined) {
 //    const tmp_storage: any = {};
 //    const p = '__unique__';  // Prefix all keys to avoid matching built-ins
 //    Storage.prototype.setItem = function(k:string, v: string){
 //      tmp_storage[p + 'authAnyplay'] = v;
 //    };
 //    Storage.prototype.setItem('authAnyplay',JSON.stringify(authData))
  // } else {
     window.localStorage.setItem('authAnyplay',JSON.stringify(authData))
  // }
  // MEMO: 60*60*24*14 = 1209600
  // document.cookie = 'authAnyplay=' + JSON.stringify(authData)+';max-age=1209600';
};

const removeAuthData = (): void => {
  // if (localStorage === undefined) {
  //   const tmp_storage: any = {};
  //   const p = '__unique__';  // Prefix all keys to avoid matching built-ins
  //   Storage.prototype.removeItem = function(k:string){
  //     delete tmp_storage[p + 'authAnyplay'];
  //   };
  //   Storage.prototype.removeItem('authAnyplay')
  // } else {
      window.localStorage.removeItem('authAnyplay');
  // }
  // document.cookie = 'authAnyplay=; Max-Age=0';
};

export { getAuthData, setAuthData, removeAuthData };
