// @ts-nocheck

import React, {useContext, useEffect} from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, withRouter } from 'react-router-dom';
import Navbar from "./components/Layout/Navbar";
import {Container, createStyles, Theme} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { AlertState } from "./context/alert/AlertState";
import { TableState } from "./context/table/TableState";
import { AlertSnack } from './components/AlertSnack';
import setup from './plugins/axios';
import {LoginContext} from "./context/login/loginContext";
import {getAuthData} from "./utils/auth";
import {AuthHeaders} from "./models/auth";
import routes from "./router";
import MyCssBaseline from './components/utils/MyCssBaseline';
import useIsOpen from './utils/hooks/useIsOpen';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth - 150,
    },
  }),
);


// @ts-ignore
const ResponsiveDrawer: React.FC = ({history}) => {
  const classes = useStyles();

  const {isOpen, toggleOpen} = useIsOpen(false)

  const { setAuthDataState, setIsAuth} = useContext(LoginContext)

  useEffect(() => history.listen(((location: { pathname: any; state: any; }, action: any) => {
    beforeEach(location.pathname)
  }), history.pathname))
  useEffect(() => {
      beforeEach(history.location.pathname)
    // eslint-disable-next-line
  },[])

  const beforeEach = (pathname: string) => {
    let checkData:AuthHeaders | null = getAuthData()

    if(pathname !== '/login' ) {
      if(!checkData ) {
        setAuthDataState(null);
        setIsAuth(false);

        history.push({
          pathname: `/login`,
          state: {
            redirect:pathname
          }
        })
      } else {
        //let route = routes.find(route => route.path.replace(':id', pathname.split('/')[2]) === pathname)
        // if(typeof route === 'undefined'){
        //   history.push('/not_found')
        // }
      }
    }
    else {
      if(checkData) {
        history.push('/')
      }
    }
  }
  // MEMO: interceptors setup here
  setup(history)
  return (
    <TableState>
        <AlertState>
          <div className={classes.root}>
            <CssBaseline />
            <MyCssBaseline />
            <Container >
             <Navbar onMenuChange={toggleOpen}/>
              <AlertSnack/>
                <main className={clsx(classes.content, {
                  [classes.contentShift]: isOpen,
                })}>
                  <div className={classes.drawerHeader} />
                  <React.Suspense fallback={<p>Loading...</p>}>
                    { routes.map((route, index) => (
                      <Route
                        key={index}
                        exact={route.mode === 'exact'}
                        strict={route.mode === 'strict'}
                        sensitive={route.mode === 'sensitive'}
                        path={route.path}
                        render={routeProps => <route.component {...routeProps} title={route.title} />}
                      />
                    ) )}
                    {/*<Redirect from='*' to='/not_found' />*/}
                  </React.Suspense>
                </main>
            </Container>
          </div>
        </AlertState>
    </TableState>
  );
}
export default withRouter(ResponsiveDrawer)
