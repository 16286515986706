// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/styles/_root.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider} from "@material-ui/core";
import anyplayTheme from "./utils/theme";
import {BrowserRouter as Router} from "react-router-dom";
import {LoginState} from "./context/login/LoginState";
import withClearCache from './components/utils/ClearCache';
const ClearCacheComponent = withClearCache(App);

ReactDOM.render(
  <LoginState>
    <Router >
      <MuiThemeProvider theme = { anyplayTheme }>
        <ClearCacheComponent />
      </MuiThemeProvider>
    </Router>
  </LoginState>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
