export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_ALERT = 'SHOW_ALERT'

export const SET_IS_AUTH = 'SET_IS_AUTH'
export const SET_AUTH_DATA = 'SET_AUTH_DATA'
export const SET_TITLE = 'SET_TITLE'
export const SET_PROFILE = 'SET_PROFILE'

export const SET_IS_PAUSED = 'SET_IS_PAUSED'
export const SET_ACTIVE_INDEX = 'SET_ACTIVE_INDEX'

export const SET_SORT_DATA = 'SET_SORT_DATA'

export const SET_REPORT_DATA = 'SET_REPORT_DATA'

