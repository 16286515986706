// @ts-nocheck

import React from 'react';
import {IRoute} from "./models/route";

const AudiobookDetailsView = React.lazy(()=>import("./views/AudiobookDetails"))
const CuratorsView = React.lazy(()=>import("./views/Curators"))
const AudiobooksView = React.lazy(()=>import("./views/Audiobooks"))
const PlaylistDetailsView = React.lazy(()=>import("./views/PlaylistDetails"))
const PlaylistsView = React.lazy(()=>import("./views/Playlists"))
const PublishersView = React.lazy(()=>import("./views/Publishers"))
const UsersView = React.lazy(()=>import("./views/Users"))
const PlaylistGroupsView = React.lazy(()=>import("./views/PlaylistGroups"))
const CuratorGroupsView = React.lazy(()=>import("./views/CuratorGroups"))
const PlaylistGroupDetailsView = React.lazy(()=>import("./views/PlaylistGroupDetails"))
const CuratorGroupDetailsView = React.lazy(()=>import("./views/CuratorGroupDetails"))
const LanguageMappingView = React.lazy(()=>import("./views/LanguageMapping"))
const LoginView = React.lazy(()=>import("./views/Login"))
const DashboardView = React.lazy(()=>import("./views/Dashboard"))
const CuratorDetailsView = React.lazy(()=>import("./views/CuratorDetails"))
const PublisherDetailsView = React.lazy(()=>import("./views/PublisherDetails"))
const UserDetailsView = React.lazy(()=>import("./views/UserDetails"))
const StatisticsView = React.lazy(()=>import("./views/Statistics"))
const NotFoundView = React.lazy(()=>import("./views/404"))
const PlaylistGeneratorDetailsView = React.lazy(()=>import("./views/PlaylistGeneratorDetails"))
const TagsManagerView = React.lazy(()=>import("./views/TagsManagerView"))
const SectionsView = React.lazy(()=>import("./views/Sections"))
const SectionsDetailsView = React.lazy(()=>import("./views/SectionsDetails"))
const CategoriesView = React.lazy(()=>import("./views/Categories"))
const CategoryDetailsView = React.lazy(()=>import("./views/CategoryDetails"))
const AuthorsView = React.lazy(()=>import("./views/Authors"))
const AuthorDetailsView = React.lazy(()=>import("./views/AuthorDetails"))
const ReviewsView = React.lazy(()=>import("./views/ReviewsView"))
const TerritoryMappingView = React.lazy(()=>import("./views/TerritoryMapping"))
const CommentsView = React.lazy(()=>import("./views/Comments"))
const ToplistView = React.lazy(()=>import("./views/Toplists"))
const LandingConstructorView = React.lazy(()=>import("./views/LandingConstructor"))
const LandingsView = React.lazy(()=>import("./views/Landings"))
const NewslettersView = React.lazy(()=>import("./views/Newsletters"))
const NewsletterDetailsView = React.lazy(()=>import("./views/NewsletterDetails"))
const CachedEditionsView = React.lazy(()=>import("./views/CachedEditions"))
const LicensesView = React.lazy(()=>import("./views/Licenses"))


// @ts-ignore
const routes:IRoute[] = [
  {
    path: '/users',
    mode: 'exact',
    isUsedInMenu: true,
    title: 'Users management',
    component: UsersView,
    icon: 'users.svg',
  },
  // {
  //   path: '/users',
  //   mode: 'exact',
  //   isUsedInMenu: false,
  //   title: 'Users',
  //   component: UsersView,
  // },
  {
    path: '/users/:id',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/edit',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/reset_password',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/authentication',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/tags',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/groups',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/languages',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/licenses',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/license_consumptions/:licenseId',
    title: 'License consumptions',
    mode: 'exact',
    isUsedInMenu: false,
    component: UserDetailsView,
  },
  {
    path: '/users/:id/playlists',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/subscription_history',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/categories',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/consumptions',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/bookmarks',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/recommendations',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/feed',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/comments',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/users/:id/following',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'User details',
    component: UserDetailsView
  },
  {
    path: '/curators',
    title: 'Curators',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorsView,
    // icon: 'curators.svg'
  },
  {
    path: '/curators/:id',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/edit',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/reset_password',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/tags',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/groups',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/languages',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/licenses',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'Curator details',
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/license_consumptions/:licenseId',
    title: 'License consumptions',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView,
  },
  {
    path: '/curators/:id/playlists',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/subscription_history',
    title: 'Curator details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorDetailsView
  },
  {
    path: '/curators/:id/categories',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'Curator details',
    component: CuratorDetailsView
  },
  {
    path: '/audiobooks',
    title: 'Audiobooks content',
    mode: 'exact',
    isUsedInMenu: true,
    component: AudiobooksView,
    icon: 'audiobooks.svg'
  },
  {
    path: '/audiobooks/:id',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/edit',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/comment_manager',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/comment_manager/:commentId',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/playlists',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/models',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/tags',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/chapters',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/caching',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/editions',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/audiobooks/:id/editions/:editionId',
    title: 'Audiobook details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AudiobookDetailsView,
  },
  {
    path: '/authors',
    title: 'Authors',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorsView
  },
  {
    path: '/authors/:id',
    title: 'Author Details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorDetailsView,
  },
  {
    path: '/authors/:id/edit',
    title: 'Author details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorDetailsView
  },
  {
    path: '/authors/:id/last_releases',
    title: 'Author Details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorDetailsView,
  },
  {
    path: '/authors/:id/upcoming_releases',
    title: 'Author Details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorDetailsView,
  },
  {
    path: '/authors/:id/most_popular',
    title: 'Author Details',
    mode: 'exact',
    isUsedInMenu: false,
    component: AuthorDetailsView,
  },
  {
    path: '/categories',
    title: 'Categories',
    mode: 'exact',
    isUsedInMenu: false,
    component: CategoriesView
  },
  {
    path: '/categories/:id',
    title: 'Category details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CategoryDetailsView
  },
  {
    path: '/categories/:id/edit',
    title: 'Category details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CategoryDetailsView,
  },
  {
    path: '/categories/:id/audiobooks',
    title: 'Category details',
    mode: 'exact',
    isUsedInMenu: false,
    component: CategoryDetailsView,
  },
  {
    path: '/playlists',
    title: 'Anyplay content',
    mode: 'exact',
    isUsedInMenu: true,
    component: PlaylistsView,
    icon: 'playlists.svg'
  },
  {
    path: '/toplists',
    title: 'Toplist',
    mode: 'exact',
    isUsedInMenu: true,
    component: ToplistView,
    icon: 'toplist.svg'
  },
  {
    path: '/playlists/:id',
    title: 'Playlist details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistDetailsView,
  },
  {
    path: '/playlists/:id/edit',
    title: 'Playlist details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistDetailsView,
  },
  {
    path: '/playlists/:id/audiobooks',
    title: 'Playlist details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistDetailsView,
  },
  {
    path: '/playlists/:id/tags',
    title: 'Playlist details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistDetailsView,
  },
  {
    path: '/playlist_generators',
    title: 'Playlist Generators',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistsView,
  },
  {
    path: '/playlist_generators/:id',
    title: 'Playlist Generator details',
    mode: 'strict',
    isUsedInMenu: false,
    component: PlaylistGeneratorDetailsView,
  },
  {
    path: '/playlist_groups',
    title: 'Playlist groups',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupsView,
    //icon: 'playlists_group.svg'
  },
  {
    path: '/playlist_groups/:id',
    title: 'Playlist group details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupDetailsView,
  },
  {
    path: '/playlist_groups/:id/edit',
    title: 'Playlist group details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupDetailsView,
  },
  {
    path: '/playlist_groups/:id/playlists',
    title: 'Playlist group details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupDetailsView,
  },
  {
    path: '/playlist_groups/:id/tags',
    title: 'Playlist group details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupDetailsView,
  },
  {
    path: '/playlist_groups/:id/sections',
    title: 'Playlist group details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PlaylistGroupDetailsView,
  },
  {
    path: '/curator_groups',
    title: 'Curator groups',
    mode: 'exact',
    isUsedInMenu: false,
    component: CuratorGroupsView,
    // icon: 'curators_group.svg'
  },
  {
    path: '/curator_groups/:id',
    title: 'Curator group details',
    mode: 'strict',
    isUsedInMenu: false,
    component: CuratorGroupDetailsView,
  },
  {
    path: '/publishers',
    title: 'Publishers',
    mode: 'exact',
    isUsedInMenu: false,
    component: PublishersView
  },
  {
    path: '/publishers/:id',
    title: 'Publisher details',
    mode: 'exact',
    isUsedInMenu: false,
    component: PublisherDetailsView,
  },
  {
    path: '/publishers/:id/per_language',
    title: 'Publisher details',
    mode: 'strict',
    isUsedInMenu: false,
    component: PublisherDetailsView,
  },
  {
    path: '/publishers/:id/last_releases',
    title: 'Publisher details',
    mode: 'strict',
    isUsedInMenu: false,
    component: PublisherDetailsView,
  },
  {
    path: '/publishers/:id/upcoming_releases',
    title: 'Publisher details',
    mode: 'strict',
    isUsedInMenu: false,
    component: PublisherDetailsView,
  },
  {
    path: '/language_mapping',
    title: 'Mapping',
    mode: 'exact',
    isUsedInMenu: true,
    component: LanguageMappingView,
    icon: 'languages.svg'
  },
  {
    path: '/reviews',
    title: 'Reviews',
    mode: 'exact',
    isUsedInMenu: true,
    component: ReviewsView,
    icon: 'review.svg'
  },
  {
    path: '/comments',
    title: 'Comments',
    mode: 'exact',
    isUsedInMenu: true,
    component: CommentsView,
    icon: 'comments.svg'
  },
  {
    path: '/statistics/languages',
    title: 'Statistics',
    mode: 'exact',
    isUsedInMenu: true,
    component: StatisticsView,
    icon: 'statistics.svg'
  },
  {
    path: '/statistics/licenses',
    title: 'Statistics',
    mode: 'exact',
    isUsedInMenu: false,
    component: StatisticsView,
  },
  {
    path: '/statistics/reports',
    title: 'Statistics',
    mode: 'exact',
    isUsedInMenu: false,
    component: StatisticsView,
  },
  {
    path: '/statistics/consumption',
    title: `User's Consumption`,
    mode: 'exact',
    isUsedInMenu: false,
    component: StatisticsView,
  },
  {
    path: '/tags_manager',
    title: 'Tags manager',
    mode: 'exact',
    isUsedInMenu: true,
    component: TagsManagerView,
    icon: 'tags.svg'
  },
  {
    path: '/territory_mapping',
    title: 'Territory mapping',
    mode: 'exact',
    isUsedInMenu: false,
    component: TerritoryMappingView,
  },
  {
    path: '/login',
    title: 'Login',
    isUsedInMenu: false,
    mode: 'exact',
    component: LoginView
  },
  {
    path: '/',
    title: 'Dashboard',
    isUsedInMenu: false,
    mode: 'exact',
    component: DashboardView
  },
  // {
  //   path: '*',
  //   mode: 'exact',
  //   title: 'Not found',
  //   isUsedInMenu: false,
  //   component: NotFoundView
  // },
  {
    path: '/not_found',
    mode: 'exact',
    title: 'Not found',
    isUsedInMenu: false,
    component: NotFoundView
  },
  {
    path: '/sections',
    mode: 'exact',
    title: 'Sections',
    isUsedInMenu: false,
    component: SectionsView
  },
  {
    path: '/sections/:id',
    title: 'Section details',
    mode: 'exact',
    isUsedInMenu: false,
    component: SectionsDetailsView,
  },
  {
    path: '/sections/:id/edit',
    title: 'Section details',
    mode: 'exact',
    isUsedInMenu: false,
    component: SectionsDetailsView
  },
  {
    path: '/sections/:id/playlist_groups',
    title: 'Section details',
    mode: 'exact',
    isUsedInMenu: false,
    component: SectionsDetailsView
  },
  {
    path: '/sections/:id/tags',
    title: 'Section details',
    mode: 'exact',
    isUsedInMenu: false,
    component: SectionsDetailsView
  },
  {
    path: '/landings',
    mode: 'exact',
    isUsedInMenu: true,
    title: 'Landings',
    component: LandingsView,
    icon: 'constructor.svg',
  },
  {
    path: '/landing_constructor/:id',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'Landing constructor',
    component: LandingConstructorView,
  },
  {
    path: '/newsletters',
    mode: 'exact',
    isUsedInMenu: true,
    title: 'Newsletters',
    component: NewslettersView,
    icon: 'mail.svg',
  },
  {
    path: '/newsletters/:id',
    mode: 'exact',
    isUsedInMenu: false,
    title: 'Newsletter details',
    component: NewsletterDetailsView,
  },
  {
    path: '/cached_editions',
    mode: 'exact',
    isUsedInMenu: true,
    title: 'Editions',
    icon: 'audiobooks.svg',
    component: CachedEditionsView,
  },
  {
    path: '/issued_licenses',
    mode: 'exact',
    isUsedInMenu: true,
    title: 'Licenses',
    icon: 'toplist.svg',
    component: LicensesView,
  }
]
export default routes;
