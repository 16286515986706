import {useState} from "react";

export const useIsLoading = () => {
  const [isLoading, setIsLoading] = useState(false)
  const loadingHandler = (param: boolean) => setIsLoading(param)
  return {
    isLoading,
    loadingHandler
  }
}
