import React from 'react';
import {NavLink} from "react-router-dom";
import { IconButton, ListItem, ListItemAvatar, Tooltip, Typography } from '@material-ui/core';
import { IAudiobookComment } from '../../models/audiobook';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  listContainer: {
    maxWidth:'520px',
    minWidth:'520px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  textPreview: {
    opacity: '0.7',
    maxWidth:'500px',
    overflow:'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
});

const NewComment:React.FC<{comment:IAudiobookComment, markAsRead(comment:IAudiobookComment):void}>
  = ({comment, markAsRead}) => {
  const classes = useStyles();
  const [isDisplayFull, setIsDisplayFull] = React.useState<boolean>(false)

  const handleMarkAsRead = (comment:IAudiobookComment) => {
    return () => {
      markAsRead(comment)
    }
  }
  return (
    <ListItem
      className={classes.listContainer}
      onMouseOver={()=>setIsDisplayFull(true)}
      onMouseLeave={()=>setIsDisplayFull(false)}
    >
      <ListItemAvatar>
        <Tooltip title="Mark as read">
          <IconButton onClick={handleMarkAsRead(comment)}>
            <FiberManualRecordIcon color={'primary'}/>
          </IconButton>
        </Tooltip>
      </ListItemAvatar>
      <NavLink
        target={'_blank'}
        to={`/audiobooks/${comment.attributes.audiobook_id}/comment_manager/${comment.id}`}>
        <Typography>
          {comment.attributes.author.data.attributes.first_name + ' ' +
          comment.attributes.author.data.attributes.last_name}
        </Typography>
        <Typography
          className={classes.textPreview}>
          {isDisplayFull ? '' : comment.attributes.body}
        </Typography>
        {
          isDisplayFull ?
            <Typography style={{maxWidth:'500px'}}>
              {comment.attributes.body}
            </Typography>
            : null
        }
      </NavLink>
    </ListItem>
  )
}
export default NewComment
