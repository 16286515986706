import React from "react";
import {NavLink} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import routes from '../../router'
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      paddingRight: '10px',
      maxWidth: 28
    }
  })
)
const LeftMenu: React.FC<{onLinkClick: any}> = ({onLinkClick}) => {
  const classes = useStyles();

  const linkClick = (routeTitle:string) => {
    onLinkClick(routeTitle)
  }
    return (
      <List>
          {routes.map((route) => (
            route.isUsedInMenu ?
              <NavLink to={route.path} className="w100" key={route.title} onClick={linkClick.bind(null,route.title)}>
                <ListItem button >
                  <img className={classes.img}
                       src={require(`../../assets/${route.icon}`).default}
                       alt="icon"/>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>: ''
          ))}
        </List>
    )
}


export default LeftMenu
