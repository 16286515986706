import {SET_AUTH_DATA, SET_IS_AUTH, SET_PROFILE, SET_TITLE} from "../types";
import {ILoginState} from "./ILoginState";
import {AuthHeaders} from "../../models/auth";

const handlers = {
  [SET_IS_AUTH]: (state:ILoginState, payload: { payload: boolean, type: 'SET_IS_AUTH' }) => ({...state, isAuth: payload.payload}),
  [SET_AUTH_DATA]: (state:ILoginState, payload:AuthHeaders) => ({...state, authData: payload}),
  [SET_TITLE]: (state:ILoginState, payload:{payload: string, type: 'SET_TITLE'}) => ({...state, title: payload.payload}),
  [SET_PROFILE]: (state:ILoginState, payload:{payload: any, type: 'SET_PROFILE'}) => ({...state, profile: payload.payload}),
  DEFAULT: (state:any) => state
}

export const loginReducer = (state: ILoginState, action: { type: any, payload?: any }) => {
  // @ts-ignore
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}
