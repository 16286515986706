import React from 'react';
import { useCallback } from 'react';

const useIsOpen = (initialState:boolean)=>{
  const [isOpen, setIsOpen] = React.useState(initialState)

  const toggleOpen = useCallback(() => setIsOpen((is) => !is),[])

  return {
    isOpen,
    toggleOpen
  }

}
export default useIsOpen
