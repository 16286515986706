import React from "react"
import { Link, IconButton  } from '@material-ui/core';
import ConnectionStatus from '../../actionCables/ConnectionStatus';

const ReportMessengerSingle:React.FC<{percent: number, link: string | null, isConnected: boolean}>
  = ({percent, link, isConnected}) => {
  return (
    <ConnectionStatus isConnected={isConnected}>
      {
        link === null ?
          <IconButton
            disabled
            component="span" style={{
            color: 'white',
            fontSize:' 1rem',
            padding: '0 0px 0px 12px'
          }}>
            {`${percent}%`}
          </IconButton>
          :
          <Link href={link} style={{ color: 'white' }}>
            Download
          </Link>
      }
    </ConnectionStatus>
  )
}
export default ReportMessengerSingle
