import React, { useReducer } from 'react';
import { initState, TableContext } from './tableContext';
import { tableReducer } from './tableReducer';
import { SET_SORT_DATA } from '../types';
import { Direction } from '../../models/misc';

export const TableState:React.FC = ({children}) => {
  const [state,dispatch]= useReducer(tableReducer, initState);

  const setSortData = (payload:{ order: string, direction: Direction }) => {
    dispatch({type:SET_SORT_DATA, payload})
  }

  return (
    <TableContext.Provider value={
      {
        sortData: state.sortData,
        setSortData
      }
    }>
      {children}
    </TableContext.Provider>
  )
}
