import axios from 'axios';
import {AuthHeaders} from "../models/auth";
import { getAuthData, setAuthData } from '../utils/auth';
import AuthService from '../service/auth';

const setAxiosHeader = (config:any) => {
  let authHeader: AuthHeaders | null = getAuthData();
  if (authHeader) {
    config.headers['access-token'] = authHeader['access-token'];
    config.headers['token-type'] = authHeader['token-type'];
    config.headers['client'] = authHeader.client;
    config.headers['expiry'] = authHeader.expiry;
    config.headers['uid'] = authHeader.uid;
  }
  return config
}
let config:any = {headers:{}}
config = setAxiosHeader(config)
export {config}
export const authService = axios.create({
  baseURL: `${process.env.REACT_APP_API_V1}`,...config,
})

export const branchService = axios.create({
  baseURL: `${process.env.REACT_APP_API_BRANCH_V1}`,
  headers: {
    "Content-Type": "application/json; charset=utf-8"
  }
})
const updateToken = (config: any) => {
  let headers = AuthService.getHeaders(config.headers)
  if(typeof headers['access-token'] !== 'undefined' && headers['access-token'] !=='') {
    setAuthData(headers)
  }
}
authService.interceptors.response.use(
  config => {
    updateToken(config)
    return config;
  },
  error => onError(error)
)
export const languageService = axios.create({
  baseURL: `${process.env.REACT_APP_API_V2}`,...config
})
export const anyplayPublicV1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_PUBLIC_V1}`,...config,
})
languageService.interceptors.response.use(
  config => {
    updateToken(config)
    return config;
  },
  error => onError(error)
)
export const findawayService = axios.create({
  baseURL: `https://api.findawayworld.com/v4/`
})
export const revenuecatServiceV1 = axios.create({
  baseURL: `https://api.revenuecat.com/v1/`,
  headers: {
    "x-platform": "ios",
    "authorization": `${process.env.REACT_APP_RC_API_KEY}`,
    "Content-Type": "application/json; charset=utf-8"
  }
})
export default function setup(history: any) {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_ADMIN_V1}`;
  axios.interceptors.request.use(
    config => {
      return setAxiosHeader(config);
    }
  );
  axios.interceptors.response.use(
    config => {
      updateToken(config)
      // if(config) {
      //   let headers = AuthService.getHeaders(config.headers)
      //   setAuthData(headers);
      //   return config;
      // }
      return config
    },
    error => onError(error, history)
  );
}
const onError = (error:any, history?: any) => {
  console.log('onError -> error', error)
  try {
    if (typeof error.response === 'undefined') {
      if (error.message === 'Network Error') {
        let customError = 'Service unavailable';
        throw new TypeError(customError);
      }
      // MEMO: in case of bad token
      // removeAuthData();
      // let customError = 'Invalid auth data';
      // history.push('/login')
      throw new TypeError('Service unavailable');
    } else {
      const {
        response: { status }
      } = error;
      let customError: any;
      switch (status) {
        case 400:
          customError = error.response.data.errors[0].detail;
          break;
        case 401:
          customError = 'You need sign in first'
          history.push('/login')
          break;
        case 403:
          customError = error.response.data.errors[0].detail.title[0]
          break;
        case 404:
          customError = error.response.data.errors[0].detail;
          history.push('/not_found')
          break;
        case 422:
          customError = error.response.data.errors[0].detail;
          break;
        default:
          customError = error.response.data.errors[0].detail;
          break;
      }
      // return Promise.reject(customError);// throw new TypeError(customError); //return new Error();
      throw new TypeError(customError)
    }
  } catch (e) {
    return Promise.reject(e);
  }
}
