// @ts-nocheck

import React from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '.MuiTablePagination-toolbar': {
        padding: '0',
      },
      '.MuiTablePagination-caption': {
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.6rem',
        },
      },
      '.MuiTablePagination-input': {
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.6rem',
        },
      },
    },
  });

interface MyCssBaselineProps extends WithStyles<typeof styles> {}

const MyCssBaseline: React.FC<MyCssBaselineProps> = ({ classes }) => {
  // The component doesn't need to return anything, as it's just applying global styles.
  return null;
};

MyCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MyCssBaseline);
