// @ts-nocheck

import React, {useContext, useEffect} from 'react';
import clsx from 'clsx';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Box,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LeftMenu from "./LeftMenu";
import {LoginContext} from "../../context/login/loginContext";
import {useHistory} from 'react-router-dom';
import {removeAuthData} from "../../utils/auth";
import AuthService from "../../service/auth";
import {useIsLoading} from "../../utils/hooks/useIsLoading";
import {AlertContext} from "../../context/alert/alertContext";
import CommentMessenger from '../CommentMessenger/CommentMessenger';
import {version} from "../../../package.json";
import ReportMessenger from '../ReportMessenger/ReportMessenger';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      position: 'relative'
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: 40,
      opacity: 0.5
    },
    version: {
      left: '16px',
      position: 'absolute',
      top: '21px'
    }
  }),
);
const Navbar: React.FC<{onMenuChange:any}> = ({onMenuChange}) =>{
  const alert = useContext(AlertContext)
  const {setAuthDataState, setIsAuth, isAuth, title, profile, setProfile} = useContext(LoginContext)

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  let {push} = useHistory()
  const { isLoading, loadingHandler } = useIsLoading()

  const [open, setOpen] = React.useState<boolean>(false);
  const [openProfile, setOpenProfile] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const [title, setTitle] = React.useState<string>(document.title);

  const handleDrawerOpen = () => {
    onMenuChange(true)
    setOpen(true);
  };
  const handleDrawerClose = () => {
    onMenuChange(false)
    setOpen(false);
  };
  const onLinkClick = () => {
    handleDrawerClose();
  }
  const getProfile = async () => {
    loadingHandler(true)
    try {
      const profileData = await AuthService.getProfile();
      setProfile(profileData)
      loadingHandler(false)
    } catch (e) {
      alert.show("Something went wrong", 'danger')
      loadingHandler(false)
    }
  }
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenProfile(true)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenProfile(false)
    setAnchorEl(null);
  };
  const signOut = () =>{
    setAuthDataState(null);
    setIsAuth(false);
    setOpen(false)
    removeAuthData()
    handleClose();
    push('/login')
  }

  useEffect(() => {
    document.title = title
    // eslint-disable-next-line
  }, [title]);
  useEffect(() => {
    if(isAuth) {
      getProfile()
    }
    // eslint-disable-next-line
  }, []);

  // @ts-ignore
  return (
  <>
    { isAuth ?
      <>
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, open && classes.hide)}
        >
          <MenuIcon />

        </IconButton>
        <Typography variant="h6"
                    noWrap
                    className={classes.title}>
          {title}
        </Typography>
        {/*<Typography variant="h6"*/}
        {/*            noWrap*/}
        {/*            className={classes.title}>*/}
        {/*  {*/}
        {/*    matches ? null :*/}
        {/*      <img className={classes.logo}*/}
        {/*           src={require(`../../assets/WhiteFaceTagline.png`).default}*/}
        {/*           alt="icon"/>*/}
        {/*  }*/}
        {/*</Typography>*/}
        <ReportMessenger/>
        <CommentMessenger/>
          <div>
            { !isLoading ?
            <>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              { profile?.attributes.avatar_image_url ?
                <Avatar src={profile?.attributes.avatar_image_url}/>
                : <Avatar>{profile?.attributes.first_name ? profile?.attributes.first_name.charAt(0) : 'A'}</Avatar>
              }
              {
                matches ? null :
                  <Box pl={1}>
                    {profile?.attributes.email.split('@')[0]}
                  </Box>
              }
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openProfile}
              onClose={handleClose}
            >
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </Menu>
            </>
              : null
            }
          </div>
      </Toolbar>
    </AppBar>
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <Typography className={classes.version}>
          {'Version: '+version}
        </Typography>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
        </IconButton>
      </div>
      <LeftMenu onLinkClick={onLinkClick}/>
    </Drawer>
      </>
      : null }
  </>
  );
}

export default Navbar
