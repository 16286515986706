import React, {useContext, useEffect} from 'react'
import CloseIcon from '@material-ui/icons/Close';

import {AlertContext} from '../context/alert/alertContext'
import {Snackbar, Slide, SlideProps, IconButton} from '@material-ui/core';

type TransitionProps = Omit<SlideProps, 'direction'>;

export const AlertSnack = () => {
  const {alert, hide} = useContext(AlertContext)

  const [transition, setTransition] = React.useState<React.ComponentType<TransitionProps> | undefined>(undefined);

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" />;
  }

  const handleClick = (Transition: React.ComponentType<TransitionProps>) => () => {
    setTransition(() => Transition);
  };

  useEffect(()=>{
    handleClick(TransitionUp);
  })

  return (
    <Snackbar
      className={alert.type}
      open={alert.visible}
      onClose={hide}
      TransitionComponent={transition}
      message={alert.text}
      key={transition ? transition.name : ''}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={hide}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}
